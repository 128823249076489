<template>
  <GModal name="modal" class="modal modal-confirm-cart">
    <div slot="header">
      <div class="modal-title">
        <div class="text-red">Cancel payment?</div>
        <div class="text">Are you sure want to cancel the payment?</div>
      </div>
    </div>
    <div slot="body">
      <div class="continue-shopping" @click="$emit('close')">
        <span>Continue payment</span>
      </div>
      <div class="cancel" @click="$emit('submit')">
        <span>Yes, cancel</span>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalCancelPayment',
};
</script>
